import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import IndexPage from '../../components/indexPage'

const ConceptionPage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/ecoconception" type="index">
      <SEO 
        title="Meilleurs sites écoresponsables de l'Observatoire - 2021"
        description="Quels sont les meilleurs sites écoresponsables de l'Observatoire de l'impact positif ? Du simple bon sens au Green IT, retrouvez les sites avec la meilleure écoconception."
        path="/2021/ecoconception"
        year="2021" />

      <Breadcrumb url="/2021/ecoconception" label="Éco-onception" year="2021" />   

      <h1 className="sr-only">Écoconception</h1>
      <IndexPage slug="conception" type="index" year="2021" />
    </Layout>
  )
  }

export default ConceptionPage

